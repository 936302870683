<template  origin="center center">
  <v-container id="offers_view" fluid class="pt-3">
    <v-row  class="pl-4 pr-7 pt-3">

            <!-- Menu -->
            <v-col cols="auto" class="pt-2" >
              <div v-if="typeUser == 1" class="breadcrumb pa-1 ml-3">
                <BreadcrumbsClient />
              </div>
              <div v-if="typeUser == 2" class="breadcrumb pa-1 ml-3">
                <BreadcrumbsTransportCompany />
              </div>
            </v-col>
            <v-spacer />
              <!-- cards -->
            <v-col cols="auto" v-if="viewOffers == 'card' || viewOffers == undefined">
              <v-tooltip bottom>
                <template #activator="{ on, attrs }">
                  <v-btn
                    color="primary"
                    small
                    icon
                    class="ml-1 textBtn"
                    v-bind="attrs"
                    v-on="on"
                    @click="loadData()"
                  >
                    <v-icon  size="small"> fa-solid fa-rotate </v-icon>
                  </v-btn>
                </template>
                <span>{{ $t("views.trips.active.update") }}</span>
              </v-tooltip>
            </v-col>

            <v-col cols="auto"  v-if="viewOffers == 'table'" >
              <v-tooltip bottom>
                <template #activator="{ on, attrs }">
                  <v-btn
                    color="white"
                    class="ml-1 textBtn"
                    v-bind="attrs"
                    small
                    icon
                    v-on="on"
                     @click="loadDataTable()"
                  >
                      <v-icon size="small"> fa-solid fa-rotate  </v-icon>
                  </v-btn>
                </template>
                <span>{{ $t("views.trips.no_assign.update") }}</span>
              </v-tooltip>
            </v-col>

            <v-col cols="auto" v-if="!load && trips.length > 0">
              <v-tooltip bottom>
                <template #activator="{ on, attrs }">
                  <v-btn
                    :color="viewOffers == 'table'  ?  $vuetify.theme.themes.light.neutral : $vuetify.theme.themes.light.primary"
                    small
                    class="ml-1 pa-3 viewBtn"
                    icon
                    v-bind="attrs"
                    v-on="on"
                    @click="saveModeCard()"
                  >
                    <v-icon  size="medium"> fa-solid fa-hard-drive </v-icon>
                  </v-btn>
                </template>
                <span>{{ $t("views.trips.offers.letter_mode") }}</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template #activator="{ on, attrs }">
                  <v-btn
                    :color="viewOffers == 'card'  ?   $vuetify.theme.themes.light.neutral : $vuetify.theme.themes.light.primary"
                    small
                    class="ml-0 viewBtn"
                    icon
                    v-bind="attrs"
                    v-on="on"
                    @click="saveModeTable()"
                  >
                    <v-icon  size="medium"> fa-solid fa-table </v-icon>
                  </v-btn>
                </template>
                <span>{{ $t("views.trips.offers.table_mode") }}</span>
              </v-tooltip>
            </v-col>
    </v-row>

    <div v-if="viewOffers == 'card' || viewOffers == undefined" id="trips_scrl">
      <v-row
        :class="!$vuetify.breakpoint.xs ? 'pl-7 pr-2' : ''"
        align="center"
        justify="center"
        v-if="!load && trips.length > 0"
      >
        <v-col cols="12" class="pt-0">
          <v-container>
            <v-row align="center" justify="center"  class="mt-4">
              <template v-for="(travel, index) in trips">
                <CardClient
                  :key="index"
                  :data="travel"
                  v-if="typeUser == 1"
                  @reloadPage="loadData()"
                />
                <CardTransportCompany
                  :key="index"
                  :data="travel"
                  v-if="typeUser !== 1 "
                  @reloadPage="loadData()"
                />
              </template>
            </v-row>
          </v-container>
        </v-col>
      </v-row>
      <Skeleton v-if="load" />
      <v-row v-if="!trips.length && !load">
        <v-col cols="12" align="center" justify="center">
          <div id="no-result">
            <v-icon>fa-solid fa-triangle-exclamation</v-icon>
            <p>
              <b>{{ $t("views.trips.offers.no_result") }}</b>
            </p>
          </div>
        </v-col>
      </v-row>
      <v-card v-intersect="infiniteScrolling" />
    </div>

    <!-- Tablas -->
    <div v-if="viewOffers == 'table' && typeUser == 1" >
        <v-col cols="auto" class="mr-2">
          <TableClient />
        </v-col>
    </div>
    <div v-if="viewOffers == 'table' && typeUser == 2">
      <v-row>
        <v-col cols="12" md="12" sm="12" lg="12" xl="12" class="pl-0 pr-3 mr-2">
          <TableTransportCompany />
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
export default {
  name: "Offers",
  metaInfo() {
    return {
      title: this.$t("components.layout.sidebar.offered"),
    };
  },
  components: {
    CardClient: () =>
      import(
        /* webpackChunkName: "CardClient" */ "@/components/views/Trip/Offers/Client/CardClient.vue"
      ),
    TableClient: () =>
      import(
        /* webpackChunkName: "TableClient" */ "@/components/views/Trip/Offers/Client/Table/Table.vue"
      ),
    TableTransportCompany: () =>
      import(
        /* webpackChunkName: "TableTransportCompany" */ "@/components/views/Trip/Offers/TransportCompany/Table/Table.vue"
      ),
    BreadcrumbsClient: () =>
      import(/* webpackChunkName: "BreadcrumbsClient" */ "@/components/views/Trip/Offers/Client/breadcrumb/BreadCrumb.vue"
      ),
    BreadcrumbsTransportCompany: () =>
      import(/* webpackChunkName: "BreadcrumbsTransportCompany" */ "@/components/views/Trip/Offers/TransportCompany/breadcrumb/BreadCrumb.vue"
      ),
    CardTransportCompany: () =>
      import(
        /* webpackChunkName: "CardTransportCompany" */ "@/components/views/Trip/Offers/TransportCompany/CardTransportCompany/CardTransportCompany.vue"
      ),
    Skeleton: () =>
      import(/* webpackChunkName: "Skeleton" */ "@/components/views/Trip/Skeleton.vue"
      ),
  },
  data() {
    return {
      trips: [],
      searchRoute: "",
      resultForPage: 10,
      totalPage: 0,
      urlCount: "/Offers/ListCount/",
      load: false,
      page: 0,
    };
  },
  mounted() {
    if (!this.token) {
      window.location.href = process.env.VUE_APP_URL_LOGIN_SUITE;
    }
    setTimeout(() => {
      this.showLoader({ show: false });
    }, 1000);

    this.loadData();
  },
  computed: {
    ...mapState(['reloadTrips']),
    ...mapGetters({
      user: "auth/user",
      token: "auth/token",
      search: "search/search",
      viewOffers: "changeView/viewOffers",
      reloadTrips: "reloadTable/reloadTrips",
    }),

    unlockUser() {
      return JSON.parse(this.user);
    },
    typeUser() {
      return this.unlockUser.user.typeUser;
    },
    url() {
      return `/Offers/${this.resultForPage}/${this.page}`;
    },
  },
  watch: {
    token(newValue, oldValue) {
      if (newValue !== oldValue) {
        if (!this.token) {
          window.location.href = process.env.VUE_APP_URL_LOGIN_SUITE;
        }
      }
    },
    search(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.loadData();
      }
    },
  },
  methods: {
    ...mapActions("loader", ["showLoader"]),
    ...mapActions("changeView", ["saveViewOffers"]),
    ...mapActions("reloadTable", ["saveReloadTrips"]),

    async loadData() {
      this.page = 0;
      this.load = true;
      this.trips = await this.service.getData(this.url, this.search);
      this.totalPage = await this.service.getDataCount(
        this.urlCount,
        this.search,
        this.resultForPage
      );
      this.load = false;
    },

    saveModeCard() {
      this.saveViewOffers({ viewOffers: "card" });
    },
    saveModeTable() {
      this.saveViewOffers({ viewOffers: "table" });
    },

    loadDataTable() {
      this.saveReloadTrips({ reloadTrips: true });
    },

    infiniteScrolling() {
      let search = this.search == "" ? "" : `?search=${this.search}`;
      setTimeout(() => {
        if (this.page <= this.totalPage) {
          this.page++;
          this.axios
            .get(`${this.url}${search}`)
            .then((response) => {
              if (response.data.length) {
                response.data.forEach((item) => this.trips.push(item));
              }
            })
            .catch((err) => {
              console.log(err);
            });
        }
      }, 500);
    },
  },
};
</script>
<style type="scss" scoped>
#offers_view {
  height: 100%;
  padding: 0;
  margin: 0;
}

#no-result,
#no-result .v-icon {
  color: #0b0045 !important;
  font-size: 2em;
}

.textBtn  {
  text-transform: none;
  color: #0b0045 !important;
  font-weight: bold;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  width: 35px;
}

.viewBtn {
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px; width: 40px;
  font-weight: bold;
  width: 40px;
}

.breadcrumb {
  background-color: #fff;
  border-radius: 8px;
}
</style>
